import React from "react";
import './EmptyState.css';
import '../../mainStyle.css'

const EmptyState = () => {
    return (
        <div className="empty-state">
            <div className="h20-bw70 empty-state-title">A breve inizierai a ricevere
                i tuoi match! Mi raccomando, tieni aperta l’app!</div>
            <div className="empty-state-image"></div>
            <div className="h20-bw70 empty-state-description">
                Nel mentre puoi goderti questi gattini :)
            </div>
        </div>
    )
}

export default EmptyState;
