import React from 'react';
import './SplashScreen.css';
import '../../mainStyle.css';
import AnimatedButton from "../Shared/AnimatedButton";

type SplashScreenProps = {
    onContinue: () => void;
}

const SplashScreen = (props: SplashScreenProps) => {
    return (
        <div className="splash-screen-page">
            <div className="splash-screen-title-logo"></div>
            <div className="splash-screen-logo"></div>
            <div className="h20-bw splash-screen-title">Ancora pochi passi</div>
            <div className="h20-bw70 splash-screen-description">
                Rispondi alle seguenti domande per poter trovare il match adatto a te!
            </div>
            <AnimatedButton className="main-button splash-screen-button" onClick={() => {props.onContinue()}}>Continua</AnimatedButton>
        </div>
    );
};


export default SplashScreen;
