import React, {ReactNode} from 'react';
import './App.css';
import {
    BrowserRouter,
    createBrowserRouter, Route, Router,
    RouterProvider, Routes, ScrollRestoration,
} from "react-router-dom";
import HomePage from "./Features/Home/HomePage";
import Survey from "./Features/Survey/Survey";
import MatchPage from "./Features/MatchPage/MatchPage";
import Onboarding from "./Features/Onboarding/Onboarding";
import ProfileCreation from "./Features/ProfileCreation/ProfileCreation";
import MatchPageDetail from "./Features/MatchPageDetail/MatchPageDetail";

const router = createBrowserRouter([
    { path: '/', element: <HomePage /> },
    {
        path: "/onboarding",
        element: <Onboarding />,
    },
    {
        path: "/profile-creation",
        element: <ProfileCreation />,
    },
    {
        path: "/survey",
        element: <Survey />,
    },
    {
        path: "/match-page",
        element: <MatchPage />,
    },
]);


function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;


