import React, { useEffect, useState} from 'react';
import './Survey.css';
import '../../mainStyle.css';
import {AnimatePresence, motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import SplashScreen from "./SplashScreen";
import {appLocalStorage} from "../../Dependencies/Storage/Storage";
import {apiManager} from "../../Dependencies/APIManager/APIManager";
import {Answer, Question} from "../../Model+Shared/Model+Shared";
import { useValidateSession} from "../Shared/hooks";



const Survey = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(-1);
    const [questions, setQuestions] = useState<Question[]>([]);

    useValidateSession()

    useEffect(() => {
        const questions: Question[] = appLocalStorage.getQuestions()?.questions ?? [];
        setQuestions(questions);
    }, []);

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top with smooth behavior
    }

    const toggleAnswerSelection = (questionId: number, answerId: number) => {
        const newQuestions: Question[] = questions.map(q => ({...q}))
        newQuestions.forEach(question => {
            if (question.id === questionId) {
                question.answers.forEach(answer => {
                    if (answer.id === answerId) {
                        answer.isSelected = !answer.isSelected ;
                    } else {
                        answer.isSelected = false;
                    }
                });
            }
        })
        setQuestions(newQuestions);
    }

    return (
        step < 0
            ? <SplashScreen onContinue={ () => { setStep(0)}}/>
            : <div className="survey-page">
            <div style={{width: "100%", display: 'flex', alignItems: 'flex-start'}}>
                <button className="h20-bw survey-back-button" onClick={() => {
                    if (step > 0) {
                        setStep(step - 1)
                    } else {
                        navigate('/profile-creation')
                    }
                }}>Indietro
                </button>
            </div>
            <div className="survey-title-logo"></div>
            <div className="survey-description h20-bw">Domanda {step + 1}/{questions.length}</div>
            <div className="survey-content">
                <AnimatePresence>
                    <motion.div
                        key={step}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={{
                            initial: {opacity: 0},
                            animate: {opacity: 1},
                            exit: {opacity: 0}
                        }}
                        transition={{duration: 0.3}}
                        style={{position: 'absolute', width: "100%", alignItems: 'center'}}
                    >
                        {
                            questions.length > 0 &&
                          <QuestionView
                            title={questions[step].text}
                            answers={questions[step].answers}
                            onClickAnswer={async (answer) => {
                                try {
                                    toggleAnswerSelection(questions[step].id, answer.id);
                                    await timeout(0.5);
                                    if (step < questions.length - 1) {
                                        setStep(step + 1);
                                        scrollToTop();
                                    } else {
                                        await apiManager.setQuestionAnswers(questions)
                                        const questionsStorage = await appLocalStorage.getQuestions();
                                        if (questionsStorage !== undefined) {
                                            appLocalStorage.setQuestions({...questionsStorage, hasCompleted: true});
                                        }

                                        navigate('/match-page');
                                    }
                                } catch (error) {
                                    console.error('Error handling click:', error);
                                }
                            }}/>
                        }
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
}

type QuestionProps = {
    title: string,
    answers: Answer[],
    onClickAnswer: (answer: Answer) => void,
}

const QuestionView = (props: QuestionProps) => {
    return (
        <div className="survey-content-page">
            <div className="h30-bw survey-question">{props.title}</div>
            {props.answers.map((answer, index) => (
                <button
                    key={index}
                    className={`h20-w survey-answer ${answer.isSelected ? 'selected' : ''}`}
                    onClick={() => {
                        props.onClickAnswer(answer);
                    }}>
                    {answer.text}
                </button>
            ))}
        </div>
    )
}

export default Survey;

const timeout = (seconds: number) => {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
};
