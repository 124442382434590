import axios, { AxiosInstance } from "axios";
import {
  EventResponse,
  Interest,
  MatchEventResponse,
  QuestionResponse,
  UserCheckInParams,
  UserCheckInResponse,
  UserProfileParams,
} from "./APIManager+Models";
import { AppLocalStorage, appLocalStorage } from "../Storage/Storage";
import { Observable } from "rxjs";
import { MatchEvent, Question } from "../../Model+Shared/Model+Shared";

type APIManagerImpl = {
  userCheckIn: (args: UserCheckInParams) => Promise<UserCheckInResponse>;
  getInterests: () => Promise<Interest[]>;
  userUpdateProfile: (args: UserProfileParams) => Promise<void>;
  userGetProfile: () => Promise<UserProfileParams | undefined>;
  getQuestions: () => Promise<Question[]>;
  setQuestionAnswers: (questions: Question[]) => Promise<void>;
  observeMatches: () => Observable<MatchEvent[]>;
  getUserEvent: (token: string) => Promise<EventResponse>;
};

export const is2xxStatus = (status: number): boolean =>
  status >= 200 && status <= 299;

function liveImplementation(
  axiosInstance: AxiosInstance,
  storage: AppLocalStorage
): APIManagerImpl {
  return {
    userCheckIn: async ({ tableNumber, locationId }) => {
      const sessionStorage = storage.getSessionStorage();

      const response = await axiosInstance.post("/users/checkin/", {
        table: {
          number: tableNumber,
          location: locationId,
        },
        token: sessionStorage?.token,
        username: sessionStorage?.username,
      });

      if (!is2xxStatus(response.status)) {
        throw new Error("Failed to check in " + response.status);
      }

      return response.data as UserCheckInResponse;
    },
    getInterests: async () => {
      const token = storage.getSessionStorage()?.token ?? "";
      const response = await axiosInstance.get("/users/interests", {
        headers: { Authorization: "Token " + token },
      });

      if (!is2xxStatus(response.status)) {
        throw new Error("Failed to get interests");
      }

      return response.data as Interest[];
    },
    userGetProfile: async () => {
      const sessionStorage = storage.getSessionStorage();
      if (!sessionStorage) {
        throw new Error("User not found");
      }
      const response = await axiosInstance.get(
        `/users/details/${sessionStorage.userId}/`,
        {
          headers: {
            Authorization: "Token " + sessionStorage.token,
          },
        }
      );

      if (!is2xxStatus(response.status)) {
        throw new Error("Failed to check in");
      }

      if (response.data !== undefined) {
        return response.data as UserProfileParams;
      }
      return undefined;
    },
    userUpdateProfile: async (args) => {
      const sessionStorage = storage.getSessionStorage();
      if (!sessionStorage) {
        throw new Error("User not found");
      }
      const response = await axiosInstance.patch(
        `/users/profile/${sessionStorage.userId}/`,
        {
          first_name: args.first_name,
          interests: args.interests.map((interest) => interest.id),
          date_of_birth: args.date_of_birth,
          sex: args.sex,
          sexual_orientation: args.sexual_orientation,
        },
        {
          headers: {
            Authorization: "Token " + sessionStorage.token,
          },
        }
      );

      if (!is2xxStatus(response.status)) {
        throw new Error("Failed to check in");
      }
    },
    getQuestions: async () => {
      const token = storage.getSessionStorage()?.token ?? "";

      const response = await axiosInstance.get("/qa/questions/", {
        headers: { Authorization: "Token " + token },
      });

      if (!is2xxStatus(response.status)) {
        throw new Error("Failed to get questions");
      }

      return (response.data as QuestionResponse[]).map((question) => {
        return {
          id: question.id,
          text: question.text,
          answers: question.answers.map((answer) => {
            return {
              id: answer.id,
              text: answer.text,
              isSelected: answer.is_selected,
            };
          }),
        };
      });
    },
    setQuestionAnswers: async (questions: Question[]) => {
      const token = storage.getSessionStorage()?.token ?? "";
      const questionsResponse: QuestionResponse[] = questions.map(
        (question) => {
          return {
            id: question.id,
            text: question.text,
            answers: question.answers.map((answer) => {
              return {
                id: answer.id,
                text: answer.text,
                is_selected: answer.isSelected,
              };
            }),
          };
        }
      );
      const response = await axiosInstance.post(
        "/qa/answers/",
        JSON.stringify(questionsResponse),
        {
          headers: { Authorization: "Token " + token },
        }
      );

      if (!is2xxStatus(response.status)) {
        throw new Error("Failed to get questions");
      }
    },
    observeMatches: () => {
      const sessionStorage = storage.getSessionStorage();
      const token = sessionStorage?.token ?? "";
      const userId = sessionStorage?.userId ?? 0;

      return new Observable<MatchEvent[]>((subscriber) => {
        const pollInterval = 60000; // 10 seconds in milliseconds

        const poll = async () => {
          const response = await axiosInstance.get(`/users/matches/${userId}`, {
            headers: { Authorization: "Token " + token },
          });

          if (!is2xxStatus(response.status)) {
            throw new Error("Failed to get matches");
          }
          const matchEvents = response.data as MatchEventResponse[];
          const matches: MatchEvent[] = matchEvents.map((match) => {
            return {
              id: match.id,
              message: match.message,
              score: match.score,
              scoreKeys: match.score_keys,
              matchScore: match.match_score,
              matchedUser: {
                firstName: match.matched_user.first_name,
                dateOfBirth: match.matched_user.date_of_birth,
                interests: match.matched_user.interests,
                table: {
                  id: match.matched_user.table.id,
                  number: match.matched_user.table.number,
                  location: match.matched_user.table.location,
                },
                score: match.score,
                scoreKeys: match.score_keys,
              },
            };
          });
          subscriber.next(matches);
        };

        // Initial poll
        poll();

        // Set up interval for subsequent polls
        const intervalId = setInterval(poll, pollInterval);

        // Cleanup function
        return () => {
          clearInterval(intervalId);
        };
      });
    },
    getUserEvent: async (token) => {
      const response = await axiosInstance.get("/users/event/", {
        headers: { Authorization: "Token " + token },
      });

      if (!is2xxStatus(response.status)) {
        throw new Error("Failed to get user event");
      }

      return response.data as EventResponse;
    },
  };
}

const mockImplementation: APIManagerImpl = {
  userCheckIn: () => {
    return Promise.resolve({
      id: 1,
      username: "test",
      token: "test",
    } as UserCheckInResponse);
  },
  getInterests: () => {
    return Promise.resolve([
      { id: 1, name: "football", icon: "⚽️" },
      { id: 2, name: "climbing", icon: "🧗‍" },
      { id: 3, name: "volleyball", icon: "🏐" },
      { id: 4, name: "football", icon: "⚽️" },
      { id: 5, name: "climbing", icon: "🧗‍" },
      { id: 6, name: "volleyball", icon: "🏐" },
      { id: 7, name: "football", icon: "⚽️" },
      { id: 8, name: "climbing", icon: "🧗‍" },
      { id: 9, name: "volleyball", icon: "🏐" },
      { id: 10, name: "football", icon: "⚽️" },
      { id: 11, name: "climbing", icon: "🧗‍" },
      { id: 12, name: "volleyball", icon: "🏐" },
      { id: 13, name: "football", icon: "⚽️" },
      { id: 14, name: "climbing", icon: "🧗‍" },
      { id: 15, name: "volleyball", icon: "🏐" },
      { id: 16, name: "football", icon: "⚽️" },
      { id: 17, name: "climbing", icon: "🧗‍" },
      { id: 18, name: "volleyball", icon: "🏐" },
      { id: 19, name: "football", icon: "⚽️" },
      { id: 20, name: "climbing", icon: "🧗‍" },
      { id: 21, name: "volleyball", icon: "🏐" },
      { id: 22, name: "football", icon: "⚽️" },
      { id: 23, name: "climbing", icon: "🧗‍" },
      { id: 24, name: "volleyball", icon: "🏐" },
    ]);
  },
  userGetProfile: () => {
    return Promise.resolve(undefined);
  },
  userUpdateProfile: () => {
    return Promise.resolve();
  },
  getQuestions: () => {
    return Promise.resolve([
      {
        id: 1,
        text: "What is your favorite color?",
        event: 1,
        answers: [
          { id: 1, text: "Red", isSelected: false },
          { id: 2, text: "Blue", isSelected: false },
          { id: 3, text: "Green", isSelected: false },
          { id: 4, text: "Yellow", isSelected: false },
        ],
      },
      {
        id: 2,
        text: "What is your favorite animal?",
        event: 1,
        answers: [
          { id: 5, text: "Dog", isSelected: false },
          { id: 6, text: "Cat", isSelected: false },
          { id: 7, text: "Elephant", isSelected: false },
          { id: 8, text: "Lion", isSelected: false },
        ],
      },
      {
        id: 3,
        text: "What is your favorite food?",
        event: 1,
        answers: [
          { id: 9, text: "Pizza", isSelected: false },
          { id: 10, text: "Pasta", isSelected: false },
          { id: 11, text: "Sushi", isSelected: false },
          { id: 12, text: "Burger", isSelected: false },
        ],
      },
    ]);
  },
  setQuestionAnswers: () => {
    return Promise.resolve();
  },
  observeMatches: () => {
    return new Observable<MatchEvent[]>((subscriber) => {
      let matches: MatchEvent[] = [];
      const pollInterval = 10000; // 10 seconds in milliseconds

      const poll = () => {
        const newMatch = generateMatches(1)[0];
        matches = [...matches, newMatch];
        subscriber.next(matches);
      };

      // Initial poll
      poll();

      // Set up interval for subsequent polls
      const intervalId = setInterval(poll, pollInterval);

      // Cleanup function
      return () => {
        clearInterval(intervalId);
      };
    });
  },
  getUserEvent: () => {
    return Promise.resolve({} as EventResponse);
  },
};

function generateMatches(count: number): MatchEvent[] {
  const firstNames = [
    "John",
    "Alice",
    "Michael",
    "Sara",
    "James",
    "Emma",
    "David",
    "Olivia",
    "Daniel",
    "Sophia",
  ];
  const locations = [
    "Barcelona",
    "Paris",
    "New York",
    "London",
    "Tokyo",
    "Sydney",
    "Berlin",
    "Rome",
    "Amsterdam",
    "Toronto",
  ];
  const interests = [
    { id: 1, name: "football", icon: "⚽️" },
    { id: 2, name: "climbing", icon: "🧗‍" },
    { id: 3, name: "volleyball", icon: "🏐" },
    { id: 4, name: "swimming", icon: "🏊‍" },
    { id: 5, name: "basketball", icon: "🏀" },
    { id: 6, name: "tennis", icon: "🎾" },
    { id: 7, name: "cycling", icon: "🚴‍" },
    { id: 8, name: "running", icon: "🏃‍" },
    { id: 9, name: "yoga", icon: "🧘‍" },
    { id: 10, name: "skiing", icon: "⛷️" },
  ];

  const matches = [];

  for (let i = 0; i < count; i++) {
    const randomFirstName =
      firstNames[Math.floor(Math.random() * firstNames.length)];
    const randomInterests = interests
      .sort(() => 0.5 - Math.random())
      .slice(0, 3); // Random 3 interests
    const randomLocation =
      locations[Math.floor(Math.random() * locations.length)];
    const randomMatchScore = +(Math.random() * (0.9 - 0.5) + 0.5).toFixed(2);
    const randomScores = [
      +Math.random().toFixed(2),
      +Math.random().toFixed(2),
      +Math.random().toFixed(2),
    ];

    matches.push({
      id: Date.now() + i, // Use timestamp + index to ensure unique IDs
      message: `You have a match with ${randomFirstName}`,
      matchScore: randomMatchScore,
      score: randomScores,
      scoreKeys: ["dimension 1", "dimension 2", "dimension 3"],
      matchedUser: {
        firstName: randomFirstName,
        dateOfBirth: new Date(
          Date.now() - Math.floor(Math.random() * 1000000000000)
        )
          .toISOString()
          .split("T")[0], // Random date of birth
        interests: randomInterests,
        table: {
          id: Math.floor(Math.random() * 100) + 1,
          number: Math.floor(Math.random() * 50) + 1,
          location: randomLocation,
        },
        score: randomScores,
        scoreKeys: ["dimension 1", "dimension 2", "dimension 3"],
      },
    });
  }

  return matches;
}

const liveAPIManager = liveImplementation(
  axios.create({
    baseURL: "http://localhost:8000",
    headers: {
      "Content-Type": "application/json",
    },
  }),
  appLocalStorage
);

export const apiManager = liveAPIManager;
