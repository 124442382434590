import {Interest, Question} from "../../Model+Shared/Model+Shared";

export enum LocalStorageKeys {
    Session = 'session',
    Interests = 'interests',
    Questions = 'questions',
    UserProfile = 'user_profile',
    Onboarding = 'onboarding',
}

export type UserProfileStorage = {
    firstName: string;
    interests: Interest[];
    dateOfBirth: string;
    sex: string;
    sexualOrientation: string;
}

export type QuestionsStorage = {
    questions: Question[]
    hasCompleted: boolean;
}

export type SessionStorage = {
    userId: number;
    username: string;
    tableNumber: number;
    token: string;
    eventID: number;
    eventExpirationDate: string;
}

export type OnboardingStorage = {
    hasCompleted: boolean;
}
