import React from 'react';
import './PageIndicator.css'; // Make sure to create this CSS file

type PageIndicatorProps = {
    numPages: number;
    currentPage: number;
}

export function PageIndicator(props: PageIndicatorProps) {
    // Create an array of dots and map over it to render the UI
    return (
        <div className="page-indicator">
            {Array.from({ length: props.numPages }, (_, index) => (
                <span
                    key={index}
                    className={`dot ${index === props.currentPage ? 'active' : ''}`}
                />
            ))}
        </div>
    );
}
