import React from 'react';
import './UserDataForm.css';
import '../../mainStyle.css';
import {InputText} from "../Shared/InputButtons/InputText";
import {InputDate} from "../Shared/InputButtons/InputDate";
import {InputDropdown} from "../Shared/InputButtons/InputDropdown";
import AnimatedButton from "../Shared/AnimatedButton";
import {Sex, SexualOrientation} from "./ProfileCreation";

type UserDataFormProps = {
    isContinueButtonActive: boolean;

    name: string | undefined;
    birthDate: string | undefined;
    sex: Sex | undefined;
    sexualOrientation: SexualOrientation | undefined;

    handleClick: () => void;
    handleNameUpdate: (name: string | undefined) => void;
    handleDateUpdate: (date: Date) => void;
    handleSexUpdate: (sex: Sex) => void;
    handleSexualOrientationUpdate: (sexualOrientation: SexualOrientation) => void;
}

const UserDataForm = (props: UserDataFormProps) => {
    return (
        <div className="user-form-container">
            <div className="user-form-inputs-container">
                <div className="h20-bw user-form-title">Inserisci i tuoi dati</div>
                <div className="user-form-section">
                    <div className="user-form-field">
                        <p className="h20-bw70">Nome</p>
                        <InputText value={props.name} placeholder="Nome" onChange={props.handleNameUpdate}/>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="user-form-section">
                    <div className="user-form-field">
                        <p className="h20-bw70">Data di nascita</p>
                        <InputDate value={new Date(props.birthDate!)} onChange={props.handleDateUpdate}/>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="user-form-section">
                    <div className="user-form-field">
                        <p className="h20-bw70">Mi identifico come</p>
                        <InputDropdown
                            value={ props.sex && { key: props.sex, value: mapSexToString(props.sex) }}
                            options={[
                                { key: Sex.Maschio, value: mapSexToString(Sex.Maschio)},
                                { key: Sex.Femmina, value: mapSexToString(Sex.Femmina)},
                                { key: Sex.Altro, value: mapSexToString(Sex.Altro)},

                            ]}
                            handleOptionSelected={(option) => { props.handleSexUpdate(option.key as Sex) }}
                        />
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="user-form-section">
                    <div className="user-form-field">
                        <p className="h20-bw70">Sono interessato a</p>
                        <InputDropdown
                            value={ props.sexualOrientation && { key: props.sexualOrientation, value: mapSexualOrientationToString(props.sexualOrientation) }}
                            options={[
                                { key: SexualOrientation.Maschi, value: mapSexualOrientationToString(SexualOrientation.Maschi) },
                                { key: SexualOrientation.Femmine, value: mapSexualOrientationToString(SexualOrientation.Femmine) },
                                { key: SexualOrientation.Entrambi, value: mapSexualOrientationToString(SexualOrientation.Entrambi) },
                            ]}
                            handleOptionSelected={(option) => { props.handleSexualOrientationUpdate(option.key as SexualOrientation) }}
                        />
                    </div>
                </div>
                <AnimatedButton
                    className={`user-form-button  ${props.isContinueButtonActive ? ' main-button' : ''}  ${props.isContinueButtonActive ? ' isActive' : ''}`}
                    isActive={props.isContinueButtonActive}
                    onClick={() => {
                        props.handleClick();
                    }}
                >
                    Continua
                </AnimatedButton>
            </div>
        </div>

    );
};

function mapSexToString(sex: Sex) {
    switch (sex) {
        case Sex.Maschio:
            return "Maschio"
        case Sex.Femmina:
            return "Femmina"
        case Sex.Altro:
            return "Altro"
    }
}

function mapSexualOrientationToString(sexualOrientation: SexualOrientation) {
    switch (sexualOrientation) {
        case SexualOrientation.Maschi:
            return "Maschi"
        case SexualOrientation.Femmine:
            return "Femmine"
        case SexualOrientation.Entrambi:
            return "Entrambi"
    }
}


export default UserDataForm;
