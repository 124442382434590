import React, { useState} from 'react';
import './InputDate.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

type InputDateProps = {
    value: Date | undefined;
    onChange?: (date: Date) => void;
}
export const InputDate = (props: InputDateProps) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    return (
        <DatePicker
            className= {`shared-custom-date-picker`}
            date={props.value}
            selected={selectedDate}
            startDate={new Date()}
            placeholderText={"Seleziona una data"}
            onChange={(date) => {
                if (date !== null) {
                    setSelectedDate(date);
                    if (props.onChange) {
                        props.onChange(date);
                    }
                }
            }}
            dateFormat="dd/MM/yyyy"
        />
    );
};
