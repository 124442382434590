import React, {useEffect, useState} from 'react';
import './Onboarding.css';
import '../../mainStyle.css'
import {useNavigate} from "react-router-dom";
import {PageIndicator} from "./PageIndicator";
import backgroundImage1 from './resources/background1.png';
import backgroundImage2 from './resources/background2.png';
import backgroundImage3 from './resources/background3.png';
import ImageTransition from "../Shared/ImageTransition";
import AnimatedButton from "../Shared/AnimatedButton";
import {appLocalStorage} from "../../Dependencies/Storage/Storage";
import { useValidateSession} from "../Shared/hooks";

type OnboardingPage = {
    backgroundImage: string;
    description: string;
}

const pages: OnboardingPage[] = [
    {
        backgroundImage: backgroundImage1,
        description: "Troverai il match perfetto per te? Scoprilo rispondendo a poche domande"
    },
    {
        backgroundImage: backgroundImage2,
        description: "Tieni d’occhio l’app perche’ durante la serata potresti ricevere nuovi match"
    },
    {
        backgroundImage: backgroundImage3,
        description: "Invia ai tuoi match dei bigliettini e cerca di conquistarli!"
    }
]
const Onboarding = () => {
    const navigation = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    useValidateSession()

    return (
        <div className="onboarding-page">
            <ImageTransition images={pages.map(page => page.backgroundImage)} index={currentPage} />
            <div className="onboarding-content">
                <p className="h18-b onboarding-description">
                    {pages[currentPage].description}
                </p>
                <div className="onboarding-page-indicator-container">
                    <PageIndicator numPages={3} currentPage={currentPage}/>
                </div>
                <AnimatedButton
                    className="onboarding-main-button"
                    onClick={() => {
                        if (currentPage === pages.length - 1) {
                            appLocalStorage.setOnboarding({
                                hasCompleted: true,
                            })
                            if (appLocalStorage.getUserProfile() !== undefined) {
                                navigation('/survey')
                                return
                            }
                            navigation('/profile-creation')
                            return
                        }
                        setCurrentPage(currentPage + 1)
                    }}
                >
                </AnimatedButton>
            </div>
        </div>)
};

export default Onboarding;
