import React, { useEffect, useRef, useState} from 'react';
import './InputDropdown.css';

export type Option = { key: string, value: string}
interface InputDropdownProps {
    value?: Option | undefined
    options: Option[]
    handleOptionSelected?: (option: Option) => void
}

export const InputDropdown = (props: InputDropdownProps) => {
    const [isActive, setIsActive] = useState(false); // To toggle dropdown visibility
    const [inputValue, setInputValue] = useState<Option | undefined>(undefined); // To store the input value
    const containerRef = useRef<HTMLDivElement | null>(null); // Ref to the container div, with TypeScript type

    const { options } = props;

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const handleOptionClick = (option: { key: string, value: string}) => {
        setInputValue(option);
        setIsActive(false); // Hide options after selection
        if (props.handleOptionSelected) {
            props.handleOptionSelected(option);
        }
    };

    // Function to handle outside clicks
    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsActive(false);
        }
    };

    // Effect to add and remove the event listener
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={'dropdown-input-container'} ref={containerRef} onClick={() => setIsActive(!isActive)}>
            <div
                className={`dropdown-input-custom-input ${isActive ? ' active' : ''} ${inputValue ? ' selected' : ''}`}
            >
                {inputValue?.value ?? "Seleziona un'opzione..."}
                <div
                    className={`dropdown-icon${isActive ? ' active' : ''}`}
                />
            </div>

            {isActive && (
                <div className="dropdown-container">
                    {options.map((option, index) => (
                        <div key={index}>
                            <div className="dropdown-item" onClick={() => handleOptionClick(option)}>
                                {option.value}
                            </div>
                            {index !== options.length - 1 && <div className="dropdown-divider"></div>}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
