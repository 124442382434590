import {
    LocalStorageKeys,
    OnboardingStorage,
    QuestionsStorage,
    SessionStorage,
    UserProfileStorage
} from "./Storage+Model";
import {Interest} from "../../Model+Shared/Model+Shared";

export interface LocalStorage {
    setItem(key: string, value: any): void;
    getItem<T>(key: string): T | undefined;
    removeItem(key: string): void;
    clear(): void;
}

const storageWrapper: LocalStorage = {
    setItem: (key: string, value: any) => {
        try {
            if (typeof value === 'object') {
                localStorage.setItem(key, JSON.stringify(value));
            } else {
                localStorage.setItem(key, JSON.stringify({ value }));
            }
        } catch (error) {
            console.error('Error saving to localStorage:', error);
        }
    },

    getItem: (key: string) => {
        try {
            const storedItem = localStorage.getItem(key);
            if (storedItem) {
                const parsedValue = JSON.parse(storedItem);
                if (typeof parsedValue === 'object' && parsedValue !== null && 'value' in parsedValue) {
                    return parsedValue.value;
                }
                return parsedValue;
            }
            return undefined;
        } catch (error) {
            console.error('Error retrieving from localStorage:', error);
            return null;
        }
    },

    removeItem: (key: string) => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error('Error removing from localStorage:', error);
        }
    },

    clear: () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error('Error clearing localStorage:', error);
        }
    }
};

export type AppLocalStorage = {
    setUserProfile: (profile: UserProfileStorage) => void,
    getUserProfile: () => UserProfileStorage | undefined,
    setQuestions: (questions: QuestionsStorage) => void,
    getQuestions: () => QuestionsStorage | undefined,
    setSessionStorage: (session: SessionStorage) => void,
    getSessionStorage: () => SessionStorage | undefined,
    setInterests: (interests: Interest[]) => void
    getInterests: () => Interest[] | undefined
    setOnboarding: (onboarding: OnboardingStorage) => void,
    getOnboarding: () => OnboardingStorage | undefined
    cleanupSessionData: () => void
}

export const appLocalStorage: AppLocalStorage = {
    setUserProfile: (profile: UserProfileStorage) => {
        storageWrapper.setItem(LocalStorageKeys.UserProfile, profile);
    },
    getUserProfile: () => storageWrapper.getItem<UserProfileStorage>(LocalStorageKeys.UserProfile),
    setSessionStorage: (session: SessionStorage) => {
        storageWrapper.setItem(LocalStorageKeys.Session, session);
    },
    getSessionStorage: () => storageWrapper.getItem<SessionStorage>(LocalStorageKeys.Session),
    setQuestions: (questions: QuestionsStorage) => {
        storageWrapper.setItem(LocalStorageKeys.Questions, questions);
    },
    getQuestions: () => storageWrapper.getItem<QuestionsStorage>(LocalStorageKeys.Questions),
    setInterests: (interests: Interest[]) => {
        storageWrapper.setItem(LocalStorageKeys.Interests, interests);
    },
    getInterests: () => storageWrapper.getItem<Interest[]>(LocalStorageKeys.Interests),
    setOnboarding: (onboarding: OnboardingStorage) => {
        storageWrapper.setItem(LocalStorageKeys.Onboarding, onboarding);
    },
    getOnboarding: () => storageWrapper.getItem<OnboardingStorage>(LocalStorageKeys.Onboarding),
    cleanupSessionData: () => {
        storageWrapper.removeItem(LocalStorageKeys.Questions);
        storageWrapper.removeItem(LocalStorageKeys.Session);


    }
}
