import React, {useEffect, useMemo, useState} from 'react';
import './InterestsForm.css';
import '../../mainStyle.css';
import SearchBox from "../Shared/SearchBox";
import AnimatedButton from "../Shared/AnimatedButton";
import {appLocalStorage} from "../../Dependencies/Storage/Storage";
import {Interest} from "../../Model+Shared/Model+Shared";

type EllipseCollectionProps = {
    interests: Interest[];
    selectedInterests: Interest[];
    handleInterestClick: (interest: Interest) => void;
}
const EllipseCollection = (props: EllipseCollectionProps) => {
    return (
        <div className="interests-collection-container">
            {props.interests.map((element, index) => {
                return (<div
                    key={index}
                    className={`h16-bw70 interests-ellipse ${props.selectedInterests.some(interest => interest.id === element.id) ? 'selected' : ''}`}
                    onClick={() => props.handleInterestClick(element)}
                >
                    {element.name + ' ' + element.icon}
                </div>)
            })}
        </div>
    );
}

type InterestsFormProps = {
    isContinueButtonActive: boolean;

    selectedInterests: Interest[];
    handleClick: () => void;
    handleInterestsUpdate: (interests: Interest[]) => void;
}

const InterestsForm = (props: InterestsFormProps) => {
    const [selectedInterests, setSelectedInterests] = useState<Interest[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [interests, setInterests] = useState<Interest[]>([]);

    useEffect(() => {
        setInterests(appLocalStorage.getInterests() ?? [])
    }, []);

    useEffect(() => {
        setSelectedInterests(props.selectedInterests);
    }, [props.selectedInterests]);

    const handleSearch = (text: string) => {
        setSearchText(text);
    };
    const filteredInterests: Interest[] = useMemo(() => {
        return interests.filter(element => element.name.toLowerCase().includes(searchText.toLowerCase()));
    }, [searchText, interests])

    const handleInterestClick = (interest: Interest) => {
        let newSelectedInterests = selectedInterests;
        if (newSelectedInterests.some(element => element.id === interest.id)) {
            newSelectedInterests = newSelectedInterests.filter(element => element.id !== interest.id);
        } else {
            if (newSelectedInterests.length < 5) {
                newSelectedInterests.push(interest);
            }
        }
        props.handleInterestsUpdate(newSelectedInterests);
    };

    const handleRemoveClick = (interest: Interest) => {
        let newSelectedInterests = selectedInterests;
        newSelectedInterests = newSelectedInterests.filter(element => element.id !== interest.id)
        props.handleInterestsUpdate(newSelectedInterests);
    };


    return (
        <div className="interests-form-container">
            <div className="interests-form-inputs-container">
                <div className="h20-bw interests-title">Scegli i tuoi interessi</div>
                <div className="interests-selected-elements-container">
                    {Array.from(selectedInterests).map(interest => (
                        <div key={interest.id} className="interests-selected-ellipse"
                             onClick={() => handleRemoveClick(interest)}>
                            {interest.name + ' ' + interest.icon }
                            <span className="interests-remove-icon">X</span>
                        </div>
                    ))}
                </div>
                <SearchBox text={searchText} onSearch={(searchTerm) => {
                    handleSearch(searchTerm);
                }}/>

                <div className="interests-content">
                    <div className="interests-scrollview-content">
                        <EllipseCollection
                            interests={filteredInterests}
                            selectedInterests={selectedInterests}
                            handleInterestClick={handleInterestClick}/>
                    </div>
                </div>

                <div className={"interests-gradient-bottom"}></div>
            </div>
            <AnimatedButton
                className={`interests-form-button  ${props.isContinueButtonActive ? ' main-button' : ''} ${props.isContinueButtonActive ? ' isActive' : ''}`}
                isActive={props.isContinueButtonActive}
                onClick={() => {
                    props.handleClick();
                }}
            >
                Continua { selectedInterests.length } / 5
            </AnimatedButton>
        </div>


    );
};


export default InterestsForm;
