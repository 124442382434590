import React from "react";
import './MatchPageDetail.css';
import '../../mainStyle.css'
import Chart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {MatchEvent} from "../../Model+Shared/Model+Shared";
import {calculateAge} from "../Shared/Helper";

type MatchPageDetailProps = {
    table: number,
    match: MatchEvent;
    handleClickBackButton: () => void;
}
const MatchPageDetail = (props: MatchPageDetailProps) => {
    const options: ApexOptions = {
        chart: {
            id: "radar",
            foreColor: "#FFFFFF",
            animations: {
                enabled: false,
            }
        },
        xaxis: {
            categories: props.match.scoreKeys
        }
    };

    const series = [
        {
            name: "Me",
            data: props.match.matchedUser.score
        },
        {
            name: props.match.matchedUser.firstName,
            data: props.match.matchedUser.score
        }
    ];
    return (
        <div className="match-page-detail">
            <div className="match-page-detail-header">
                <div className="h20-bw match-page-detail-back-header" onClick={() => {
                    props.handleClickBackButton()
                }}>Indietro
                </div>
                <div className="h20-bw match-page-detail-table-header">Tavolo {props.table}</div>
            </div>

            <div className="h30-bw match-page-detail-title">{props.match.matchedUser.firstName}</div>
            <div className="match-page-detail-container">
                <div className="h20-bw match-page-detail-row ">Tavolo {props.match.matchedUser.table.number}</div>
                <div className="h20-bw match-page-detail-row ">{calculateAge(new Date(props.match.matchedUser.dateOfBirth!))} anni</div>
                <div className="h20-bw match-page-detail-row ">Compatibilità: {Math.floor(props.match.matchScore * 1000) / 10}%</div>
            </div>
            <div className="match-page-detail-container">
                <div className="h20-bw match-page-detail-row ">Interessi in comune:</div>
                <div className="h20-bw match-page-detail-interests-list ">
                    {props.match.matchedUser.interests.map((interest, index) => (
                        <div className="match-page-detail-interests-item" key={index}>
                            <span className="match-page-detail-interests-item-text">{interest.icon + " " + interest.name}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="match-page-detail-container">
                <div className="h20-bw match-page-detail-row ">Personalità:</div>
                <div className="match-page-detail-chart">
                    <Chart
                        options={options}
                        series={series}
                        type="radar"
                        width="300"
                        height="300"
                    />
                </div>
            </div>
            <div style={{height:20}}></div>
        </div>
    )
};

export default MatchPageDetail;
