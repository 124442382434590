import React, {useState} from 'react';
import './ProfileCreation.css';
import '../../mainStyle.css';
import {useNavigate} from "react-router-dom";
import UserDataForm from "./UserDataForm";
import {AnimatePresence, motion} from "framer-motion";
import InterestsForm from "./InterestsForm";
import {apiManager} from "../../Dependencies/APIManager/APIManager";
import {appLocalStorage} from "../../Dependencies/Storage/Storage";
import {Interest} from "../../Model+Shared/Model+Shared";
import {useValidateSession} from "../Shared/hooks";

export enum Sex {
    Maschio = 'M',
    Femmina = 'F',
    Altro = 'O',
}

export enum SexualOrientation {
    Maschi = 'M',
    Femmine = 'F',
    Entrambi = 'B',
}

type UserData = {
    name?: string;
    birthDate?: string;
    sex?: Sex;
    sexualOrientation?: SexualOrientation;
    interests: Interest[];
}

const ProfileCreation = () => {
    const navigate = useNavigate();
    useValidateSession()

    const [step, setStep] = useState(0);
    const [userData, setUserData] = useState<UserData>({
        name: undefined,
        birthDate: undefined,
        sex: undefined,
        sexualOrientation: undefined,
        interests: [],
    });

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top with smooth behavior
    }

    async function nextPageIfNeeded() {
        if (step < pages.length - 1) {
            setStep(step + 1);
            scrollToTop();
        } else {
            try {
                await apiManager.userUpdateProfile({
                    first_name: userData.name!,
                    interests: userData.interests,
                    date_of_birth: userData.birthDate!,
                    sex: userData.sex!,
                    sexual_orientation: userData.sexualOrientation!,
                });
                appLocalStorage.setUserProfile({
                    firstName: userData.name!,
                    interests: userData.interests,
                    dateOfBirth: userData.birthDate!,
                    sex: userData.sex!,
                    sexualOrientation: userData.sexualOrientation!,
                });
                // Suppose that if you don't have the profile you don't have the questions
                const questionsResponse = await apiManager.getQuestions();
                appLocalStorage.setQuestions({ questions: questionsResponse, hasCompleted: false });
                navigate('/survey');
            } catch (error) {
                // TODO: Handle alert
            }
        }
    }

    const pages = [
        <UserDataForm
            isContinueButtonActive={
                userData.name !== undefined &&
                userData.birthDate !== undefined &&
                userData.sex !== undefined &&
                userData.sexualOrientation !== undefined
            }
            name={userData.name}
            birthDate={userData.birthDate}
            sex={userData.sex}
            sexualOrientation={userData.sexualOrientation}
            handleClick={nextPageIfNeeded}
            handleNameUpdate={(text) => { setUserData({...userData, name: text})}}
            handleDateUpdate={(date) => {
                const formattedDate = date.toISOString().split('T')[0];
                setUserData({...userData, birthDate: formattedDate})}}
            handleSexUpdate={(sex) => { setUserData({...userData, sex: sex})}}
            handleSexualOrientationUpdate={(sexualOrientation) => { setUserData({...userData, sexualOrientation: sexualOrientation})}}
        />,
        <InterestsForm
            isContinueButtonActive={
                userData.interests.length >= 3
            }
            selectedInterests={userData.interests}
            handleClick={nextPageIfNeeded}
            handleInterestsUpdate={(interests) => { setUserData({...userData, interests: interests})}}
        />,
    ]

    return (
        <div className="profile-creation-page">
            <div style={{width: "100%", display: 'flex', alignItems: 'flex-start'}}>
                <button className="h20-bw profile-creation-back-button" onClick={() => {
                    if (step > 0) {
                        setStep(step - 1)
                    } else {
                        navigate('/onboarding')
                    }
                }}>Indietro
                </button>
            </div>
            <div className="profile-creation-content">
                <AnimatePresence>
                    <motion.div
                        key={step}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={{
                            initial: {opacity: 0},
                            animate: {opacity: 1},
                            exit: {opacity: 0}
                        }}
                        transition={{duration: 0.3}}
                        style={{position: 'absolute', width: '100%', height: '95%'}}
                    >
                        {pages[step]}
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};


export default ProfileCreation;
