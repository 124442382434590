import React, {useEffect, useState, useRef} from "react";
import './MatchPage.css';
import '../../mainStyle.css'
import EmptyState from "./EmpyState";
import {FixedSizeList as List, ListChildComponentProps} from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import {apiManager} from "../../Dependencies/APIManager/APIManager";
import { MatchEvent} from "../../Model+Shared/Model+Shared";
import {useValidateSession} from "../Shared/hooks";
import MatchPageDetail from "../MatchPageDetail/MatchPageDetail";
import {appLocalStorage} from "../../Dependencies/Storage/Storage";
import Modal from 'react-modal';
import {calculateAge} from "../Shared/Helper";
import { Subscription } from 'rxjs';

type MatchListProps = {
    matches: MatchEvent[];
    newMatchIds: Set<number>;
    handleClickMatch: (match: MatchEvent) => void;
}
const MatchList = (props: MatchListProps) => {
    const renderRow = ({ index, style }: ListChildComponentProps) => {
        const match = props.matches[index];
        const isNewMatch = props.newMatchIds.has(match.id);
        return (
            <div style={style}>
                <MatchPageContent 
                    match={match}
                    isNewMatch={isNewMatch}
                    handleClickMatch={() => {
                        props.handleClickMatch(match)
                    }}
                />
            </div>
        )
    };

    return (
        <div className="match-page-list-container">
            <AutoSizer>
                {({ height, width }: Size) => (
                    <List
                        className="match-page-list"
                        height={height} // Adjust based on the height of each item
                        itemCount={props.matches.length}
                        itemSize={260} // Adjust based on the width of each item
                        width={width}
                        layout="vertical"
                    >
                        {renderRow}
                    </List>
                )}
            </AutoSizer>
        </div>
    )
}
const MatchPage = () => {
    const [matches, setMatches] = useState<MatchEvent[]>([]);
    const [newMatchIds, setNewMatchIds] = useState<Set<number>>(new Set());
    const [selectedMatchId, setSelectedMatchId] = useState<number | undefined>(undefined);
    const [messageAlert, setMessageAlert] = useState<string | undefined>(undefined);
    const tableNumber = appLocalStorage.getSessionStorage()?.tableNumber ?? 0;
    const previousMatchIdsRef = useRef<Set<number>>(new Set());
    const isFirstUpdateRef = useRef(true);
    useValidateSession()

    useEffect(() => {
        const subscription: Subscription = apiManager.observeMatches().subscribe({
            next: (newMatches) => {
                if (isFirstUpdateRef.current) {
                    isFirstUpdateRef.current = false;
                    setMatches(newMatches);
                    previousMatchIdsRef.current = new Set(newMatches.map(match => match.id));
                    return;
                }

                const currentMatchIds = new Set(newMatches.map(match => match.id));
                const newMatchesFiltered = newMatches.filter(match => !previousMatchIdsRef.current.has(match.id));
                const newMatchCount = newMatchesFiltered.length;
                
                if (newMatchCount > 0) {
                    setMessageAlert(`Hey! Hai appena ricevuto ${newMatchCount} nuov${newMatchCount === 1 ? 'o' : 'i'} match!`);
                    setNewMatchIds(prev => {
                        const updated = new Set(prev);
                        newMatchesFiltered.forEach(match => updated.add(match.id));
                        return updated;
                    });
                }
                
                setMatches(newMatches);
                previousMatchIdsRef.current = currentMatchIds;
            },
            error: (error) => {
                console.error("Error observing matches:", error);
                setMessageAlert("Failed to fetch matches. Please try again later.");
            }
        });

        // Cleanup function
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const handleClickMatch = (match: MatchEvent) => {
        setSelectedMatchId(match.id);
        if (newMatchIds.has(match.id)) {
            setNewMatchIds(prev => {
                const updated = new Set(prev);
                updated.delete(match.id);
                return updated;
            });
        }
    };

    const basePage = (
        <div className="match-page">
            <div className="match-page-header">
                <div className="h20-bw match-page-table-header">Tavolo {tableNumber}</div>
                <div className="match-page-settings"></div>
            </div>

            <div className="h30-bw match-page-title">I miei match</div>
            {matches.length === 0 ? <EmptyState/> : 
                <MatchList 
                    matches={matches} 
                    newMatchIds={newMatchIds}
                    handleClickMatch={handleClickMatch}
                />
            }
        </div>
    )

    return (
        <div className="match-page-main">
            { selectedMatchId && <MatchPageDetail
              table={tableNumber}
              match={matches.find(match => match.id === selectedMatchId)!}
              handleClickBackButton={() => {setSelectedMatchId(undefined)}}
            /> }
            {!selectedMatchId && basePage}
            <Modal
                isOpen={messageAlert !== undefined}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: "#121315",
                        border: "2px solid #FFFFFF",
                        alignItems: "center",
                        flexDirection: "column",
                        display: "flex",
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                }}
                contentLabel="Example Modal"
            >
                <h2 className='h20-bw'>{messageAlert}</h2>
                <button className="main-button modal-match-page" onClick={() => {
                    setMessageAlert(undefined)
                }}>Chiudi</button>
            </Modal>
        </div>
    )
};

type MatchPageContentProps = {
    match: MatchEvent;
    isNewMatch: boolean;
    handleClickMatch: (match: MatchEvent) => void;
}

const MatchPageContent = (props: MatchPageContentProps) => {
    const match = props.match;

    return (
        <div className="match-page-content" onClick={() => {
            props.handleClickMatch(props.match)
        }}>
            <div className="match-page-match-container" style={{ border: `2px solid ${props.isNewMatch ? "#E20562" : "#FFFFFF"}` }}>
                <div className="h20-bw match-page-match-table">Tavolo {match.matchedUser.table.number}</div>
                <div className="h30-bw match-page-match-name">{match.matchedUser.firstName}</div>
                <div className="h20-bw match-page-match-age">{calculateAge(new Date(match.matchedUser.dateOfBirth!))} anni</div>
                <div className="h30-bw match-page-match-interests">{match.matchedUser.interests.map(interest => interest.icon).join('  ')}</div>
                <div className="h40-bw match-page-match-score">{Math.floor(match.matchScore * 1000) / 10}%</div>
            </div>
        </div>
    )
}
export default MatchPage;
