export function calculateAge(dateOfBirth: Date): number {
    const today = new Date();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const monthsDifference = dateOfBirth.getMonth() - dateOfBirth.getMonth();
    const daysDifference = dateOfBirth.getDate() - dateOfBirth.getDate();

    if (monthsDifference < 0 || (monthsDifference === 0 && daysDifference < 0)) {
        age--;
    }

    return age;
}
