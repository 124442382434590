import React from 'react';
import './InputText.css';

interface InputTextProps {
    value: string | undefined,
    placeholder?: string,
    onChange?: (text: string | undefined) => void;
}

export const InputText = (props: InputTextProps) => {
    return (
        <div className={'input-container'} >
            <input
                className="input-custom-input"
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event) => {
                    if (props.onChange) {
                        props.onChange(event.target.value === '' ? undefined : event.target.value);
                    }
                }}
            /></div>

    );
};
