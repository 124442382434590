import React from "react";
import "./ImageTransition.css";

type ImageTransitionProps = {
    images: string[];
    index: number;
}

function ImageTransition(props: ImageTransitionProps) {
    return (
        <div className="image-transition-container">
            {props.images.map((url, index) => (
                <img
                    key={index}
                    src={url}
                    className="image-transition-fade-image"
                    style={{ opacity: index === props.index ? 1 : 0 }}
                    alt={`Background ${index}`}
                />
            ))}
        </div>
    );
}

export default ImageTransition;
