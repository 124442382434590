import React from 'react';
import './HomePage.css';
import '../../mainStyle.css'
import {useNavigate} from "react-router-dom";
import AnimatedButton from "../Shared/AnimatedButton";
import {apiManager} from "../../Dependencies/APIManager/APIManager";
import {appLocalStorage} from "../../Dependencies/Storage/Storage";
import {UserProfileParams} from "../../Dependencies/APIManager/APIManager+Models";
import {UserProfileStorage} from "../../Dependencies/Storage/Storage+Model";

const HomePage = () => {
    const navigation = useNavigate();
    const tableNumber = getQueryParam('table_number')
    const locationId = getQueryParam('location_id')

    setTimeout(function () {
        window.scrollTo(0, 1);
    }, 1000);

    return (
        <div className="home-page">
            <div className="home-page-background"></div>
            <div className="home-page-content">
                <div className="home-page-logo"></div>
                {
                    locationId && tableNumber
                        ?
                        <AnimatedButton
                            className="main-button home-page-button"
                            onClick={async () => {
                                try {
                                    const checkInResponse = await apiManager.userCheckIn({ tableNumber: Number(tableNumber), locationId: locationId })
                                    const eventData = await apiManager.getUserEvent(checkInResponse.token);
                                    const currentSessionStorage = await appLocalStorage.getSessionStorage();
                                    // Clean up the session data if the event is changed
                                    if (currentSessionStorage?.userId !== checkInResponse.event_id) {
                                        await appLocalStorage.cleanupSessionData();
                                    }

                                    // Save the current session data
                                    appLocalStorage.setSessionStorage({
                                        tableNumber: Number(tableNumber),
                                        token: checkInResponse.token,
                                        userId: checkInResponse.id,
                                        username: checkInResponse.username,
                                        eventID: checkInResponse.event_id,
                                        eventExpirationDate: eventData.date_end,
                                    })

                                    const interestsResponse = await apiManager.getInterests();
                                    appLocalStorage.setInterests(interestsResponse);

                                    const onboarding = await appLocalStorage.getOnboarding();

                                    if (onboarding?.hasCompleted !== true) {
                                        navigation('/onboarding');
                                        return;
                                    }
                                    const localUserProfile = appLocalStorage.getUserProfile()
                                    if (!localUserProfile || !isValidLocalUserProfile(localUserProfile)) {
                                        navigation('/profile-creation');
                                        return;
                                    } else {
                                        await apiManager.userUpdateProfile({
                                            first_name: localUserProfile.firstName,
                                            interests: localUserProfile.interests,
                                            date_of_birth: localUserProfile.dateOfBirth,
                                            sex: localUserProfile.sex,
                                            sexual_orientation: localUserProfile.sexualOrientation,
                                        });
                                    }

                                    const localQuestions = appLocalStorage.getQuestions();
                                    // If I already answers, go to match page and update the questions in the server
                                    if (localQuestions?.hasCompleted === true) {
                                        await apiManager.setQuestionAnswers(localQuestions.questions);
                                        navigation('/match-page');
                                        return;
                                    } else {
                                        // Ask for questions and save them
                                        const questions = await apiManager.getQuestions();
                                        appLocalStorage.setQuestions({ questions: questions, hasCompleted: false });
                                        navigation('/survey');
                                        return;
                                    }
                                } catch (error) {
                                    alert("Errore: controlla la tua connessione o riprova. Code: " + error)
                                }
                            }}
                        >
                            Tavolo {tableNumber} - Inizia
                        </AnimatedButton>

                        : <p className="h18-b-gray home-page-message-description"> Scannerizza il QR code sul tuo tavolo per iniziare a giocare!</p>
                }
            </div>
        </div>)}

export default HomePage;


function getQueryParam(param: string): string | null {
    // Assuming you're in a browser and using the current page's URL
    const queryString = window.location.search;

    // Use URLSearchParams to parse the query string
    const urlParams = new URLSearchParams(queryString);

    // Get the parameter value by name
    return urlParams.get(param);
}

function isValidUserProfile(params: UserProfileParams): boolean {
    return params.first_name !== '' &&
        params.interests.length > 0 &&
        params.date_of_birth !== null &&
        params.sex !== null &&
        params.sexual_orientation !== null
}

function isValidLocalUserProfile(params: UserProfileStorage): boolean {
    return params.firstName !== '' &&
        params.interests.length > 0 &&
        params.dateOfBirth !== null &&
        params.sex !== null &&
        params.sexualOrientation !== null
}



