import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {appLocalStorage} from "../../Dependencies/Storage/Storage";

export const useValidateSession = () => {
    const navigation = useNavigate();
    const [isSessionValid, setIsSessionValid] = useState(false);
    useEffect(() => {
        const session = appLocalStorage.getSessionStorage()
        if (session) {
            if (Date.now() > new Date(session.eventExpirationDate).getTime()) {
                setIsSessionValid(false)
                navigation('/')
                return;
            }
        } else {
            navigation('/')
            setIsSessionValid(false)
            return
        }

        setIsSessionValid(true)
    }, [navigation]);

    return isSessionValid;
}
