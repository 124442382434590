import React, {ChangeEvent} from "react";
import './SearchBox.css';
interface SearchBoxProps {
    text: string;
    onSearch: (searchTerm: string) => void;
}
const SearchBox = (props: SearchBoxProps) => {

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onSearch(event.target.value);
    };

    const handleKeyPress = () => {

    };

    return (
        <div className="search-box">
            <input
                type="text"
                value={props.text}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Search..."
                className="search-input"
            />
        </div>
    );
};

export default SearchBox;
