import React, {ReactNode, useState} from "react";
import './AnimatedButton.css';

interface AnimatedButtonProps {
    children?: ReactNode;
    className?: string;
    isActive?: boolean;
    onClick: () => void;
}

const AnimatedButton: React.FC<AnimatedButtonProps> = (props: AnimatedButtonProps) => {
    const [isPressed, setIsPressed] = useState(false);

    return (
        <button
            className={`button-animated ${isPressed && (props.isActive ?? true) ? 'pressed' : ''} ${props.className}`}
            onMouseDown={() => setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onMouseLeave={() => setIsPressed(false)} // Reset on leave
            onTouchStart={() => setIsPressed(true)}
            onTouchEnd={() => setIsPressed(false)}
            onClick={() => { if (props.isActive ?? true) { props.onClick() }}}
        >
            {props.children && props.children}
        </button>
    );
}

export default AnimatedButton;
